.layout-top {
  width: 100%;
  height: 76px;
  background-color: rgb(0, 100, 255);
  color: rgb(255, 255, 255);
  font-size: 40px;
  font-weight: 600;
  display: grid;
  grid-template-columns: repeat(30, 1fr);
  grid-template-rows: repeat(3, 1fr);
  align-items: center;
}

.layout-top-logo {
  grid-column: 2;
  grid-row: 2;
}

.layout-top-user {
  font-size: 1rem;
  grid-column: 18 / 30;
  grid-row: 2;
  justify-items: end;
  align-content: end;
  grid-row: 2;
  display: flex;
  align-items: end;
  justify-content: flex-end;
  cursor: pointer;
}

.layout-top-user:hover {
  color: rgb(235, 235, 235);
}

.layout-content {
  display: flex;
}

.layout-center {
  //background-color: #e9edf5;
  //background-color: rgb(249, 250, 251);
  width: calc(100% - 230px);
  height: calc(100% - 76px);
  left: 200px;
  position: fixed;
  padding-left: 30px;
  overflow: auto;
}
  
.layout-left {
  //position: fixed;
  width: 203px;
  height: 100%;
  padding: 20px 0 0 50px;
}

.layout-left > div {
  margin-top: 20px;
}

.layout-left > div:hover {
  color: rgb(255,26,0);
}

.layout-top-menu {
  display: none;
}

@media (max-width: 1060px) {
  .layout-center {
    background-color: rgb(255, 255, 255);
    width: 100%;
    height: 100%;
    position: fixed;
    padding-left: 30px;
    left: 0px;
  }
  .layout-top-menu {
    display: flex;
    grid-column: 2;
    grid-row: 2;
  }
  .layout-top-logo {
    grid-column: 3;
    grid-row: 2;
  }
  .layout-left {
    position: center;
    width: 203px;
    height: 100%;
    padding: 20px 0 0 50px;
    z-index: 999;
    background: white;
    box-shadow: 2px 2px 2px 2px #F2F2F2;
  }
}

@media (max-width: 1200px) {
  .layout-center {
    background-color: rgb(255, 255, 255);
    width: 100%;
    height: 100%;
    position: fixed;
    padding-left: 30px;
    left: 0px;
  }
  .layout-top-menu {
    display: flex;
    grid-column: 2;
    grid-row: 2;
  }
  .layout-top-logo {
    grid-column: 3;
    grid-row: 2;
  }
  .layout-left {
    position: fixed;
    width: 203px;
    height: 100%;
    padding: 20px 0 0 50px;
    z-index: 999;
    background: white;
    box-shadow: 2px 2px 2px 2px #F2F2F2;
  }
}