.paper-style-small-box {
  width: 20rem;
  height: 20rem;
}

.paper-style-large-box {
  width: 42rem;
  height: 25rem;
}

.dashboard-list-column {
  display: flex;
  gap: 2rem;
  margin-bottom: 2rem;
}

.dashboard-list-active {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  height: 18rem;
  justify-content: center;
}

.dashboard-list-active > div {
  display: flex;
  justify-content: space-between;
}

.dashboard-list-title {
  margin: 1rem 0 1rem 1rem;
}

.dashboard-list-recently-company {
  padding: 1rem;
  height: 18rem;
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
}

.dashboard-list-recently-company > div {
  display: flex;
  justify-content: space-between;
}

.dashboard-list-recently-company-detail {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 9rem;
}

.dashboard-list-recently-company-detail::after {
  content: '';
  display: block;
}

.dashboard-list-expired-company {
  padding: 1rem;
  height: 14rem;
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
  overflow-y: auto;
}

.dashboard-list-expired-company > div > div {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.dashboard-list-expired-text {
  color: rgb(176, 0, 32);
  font-weight: 500;
  font-size: 0.9rem;
}

.dashboard-list-expired-color {
  color: rgb(52, 71, 103);
  font-size: 0.9rem;
}

.dashboard-list-small-no-data {
  height: 14rem;
  display: flex;
  align-items: center;
}

.dashboard-list-small-no-data > div {
  width: 18rem;
  text-align: center;
}

@media (max-width: 1200px) {
  .dashboard-list-column {
    display: flex;
    flex-wrap: wrap;
    gap: 3rem;
    margin-bottom: 2rem;
  }
}
  
@media (max-width: 900px) {
  .dashboard-list-column {
    display: flex;
    gap: 2rem;
    margin-bottom: 2rem;
  }
}