@charset "UTF-8";

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR&display=swap');
@import 'signin/signin';
@import 'main/layout';
@import 'company/list';
@import 'company/detail';
@import 'account/list';
@import 'dashboard/list';

body {
  padding: 0;
  margin: 0;
  font-family: "Noto Sans KR", sans-serif;
}

a {
  text-decoration: none;
  color: inherit;  
}