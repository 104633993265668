.account-list-content-email {
  width: 19%;
  text-align: center;
  padding-left: 10px;
  display: flex;
  justify-content: center;
}

.account-list-content-name {
  width: 18%;
  text-align: center;
}
  
.account-list-content-enable{
  width: 20%;
  text-align: center;
}
  
.account-list-content-last-login {
  width: 23%;
  text-align: center;
 }
  
.account-list-content-regdate {
  width: 20%;
  text-align: center;
}

.account-modify-dialog {
  width: 550px;
}

.account-list-skeleton {
  margin-top: 2rem;
}