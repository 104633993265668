.detail-wrapper {
  width: 1400px;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.paper-style {
  max-height: 750px;
  margin-bottom: 30px;
  overflow-y: auto;
}

.paper-style-end {
  max-height: 750px;
  margin-bottom: 30px;
  overflow-y: auto;
}

.detail-information {
  padding: 20px;
  line-height: 40px;
}

.detail-information-content {
  border-bottom: 1px solid rgb(224,224,224);
  display: flex;
}

.detail-information-column {
  padding-left: 20px;
  width: 250px;
}

.detail-license-list {
  padding: 20px;
  display: flex; 
  flex-wrap: wrap;
  gap: 20px;
}

.detail-license-paper {
  padding: 20px;
  width: 610px;
  background-color: rgb(248, 249, 250) !important;
  //border-top: 5px solid rgb(26, 118, 220);
}

.detail-license-paper:hover {
  background-color: rgb(240,240,240) !important;
  cursor: pointer;
}

.detail-license-add {
  padding: 20px;
  width: 610px;
  height: 95px;
  background-color: rgb(248, 249, 250) !important;
  border: 2px dashed rgb(26, 118, 220);
  display: flex;
  align-items: center;
  justify-content: center;
}

.detail-license-add:hover {
  background-color: rgb(240,240,240) !important;
  cursor: pointer;
}

.detail-license-column {
  display: flex;
  justify-content: space-between;
}

.opacity {
  opacity: 0.6;
}

.detail-button {
  width: 100px;
  height: 40px;
  font-weight: 600;
  margin-bottom: 1rem;
}

.detail-log-message-list {
  min-height: 270px;
}

.detail-informaition-detail-memo {
  width: 67rem;
  white-space: pre;
}

.detail-log-message-box {
  margin: 5px 30px 5px 10px;
  background-color: rgb(235,245,241);
  padding: 10px 10px 10px 10px;
  overflow-wrap: break-word;
  white-space: pre-wrap;
  border-radius: 5px;
  display: inline-block;
  max-width: 1300px;
}

.detail-log-message-input {
  height: 100px;
  border-top:1px solid rgb(224,224,224);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px 0 20px;
  gap: 10px;
}

.detail-log-message-date {
  color: rgb(200,200,200);
  font-size: 12px;
  margin-right: 20px;
}

.detail-log-message-content {
  display: flex;
  align-items: center;
  gap: 10px;  
  margin-left: 10px;
}

.detail-log-date-divider {
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 10px;
}

.modify-license-title-download-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.modify-license-clipboard-text {
  padding: 0rem;
  margin: 0rem;
  cursor: pointer;
}

.modify-license-clipboard-text:hover {
  color: rgb(25,118,210);
}

@media (max-width: 1200px) {
  .detail-wrapper {
    width: 1100px;
    margin-top: 40px;
  }
  .paper-style-end {
    max-height: 750px;
    margin-bottom: 80px;
    overflow-y: auto;
  }
  .detail-license-paper {
    padding: 20px;
    width: 460px;
    background-color: rgb(248, 249, 250) !important;
    //border-top: 5px solid rgb(26, 118, 220);
  }
  .detail-license-add {
    padding: 20px;
    width: 460px;
    height: 95px;
    background-color: rgb(248, 249, 250) !important;
    border: 2px dashed rgb(26, 118, 220);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .detail-log-message-box {
    margin: 5px 30px 5px 10px;
    background-color: rgb(235,245,241);
    padding: 10px 10px 10px 10px;
    overflow-wrap: break-word;
    border-radius: 5px;
    display: inline-block;
    max-width: 1000px;
  }
}

@media (max-width: 900px) {
  .detail-wrapper {
    width: 830px;
    margin-top: 40px;
  }
  .detail-license-paper {
    padding: 20px;
    width: 320px;
    background-color: rgb(248, 249, 250) !important;
    //border-top: 5px solid rgb(26, 118, 220);
  }
  .detail-license-add {
    padding: 20px;
    width: 320px;
    height: 95px;
    background-color: rgb(248, 249, 250) !important;
    border: 2px dashed rgb(26, 118, 220);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .detail-log-message-box {
    margin: 5px 30px 5px 10px;
    background-color: rgb(235,245,241);
    padding: 10px 10px 10px 10px;
    overflow-wrap: break-word;
    border-radius: 5px;
    display: inline-block;
    max-width: 750px;
  }
}