.login-main {
  width: 100%;
  height: 600px;
  display: flex;
  flex-direction: column; 
  justify-content: center;
  align-items: center;
}

.login-title {
  color: rgb(0, 100, 255);
  font-family: sans-serif;
  font-size: 40px;
  font-weight: 800;
}

.login-contents {
  margin-top: 50px;
  height: 230px;
  width: 250px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

