.list-wrapper {
  width: 1400px;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.list-etc {
  display: flex;
  align-items: center;
  gap: 20px;
}

.list-etc > Button {
  width: 7rem;
  height: 2.5rem;
  font-weight: 600;
}

.list-etc > div {
  width: 300px;
}

.paper-style {
  max-height: 750px;
  overflow-y: auto;
}

.list-column {
  position: sticky;
  top: 0; 
  background-color: rgb(255, 255, 255);
  display: flex;
  height: 50px;
  justify-content: space-around;
  align-items: center;
  border-bottom: 1px solid rgb(224,224,224);
  font-weight: 600;
  margin-left: 20px;
}

.list-paging {
  position: sticky;
  bottom: 0; 
  background-color: rgb(255, 255, 255);
  display: flex;
  height: 50px;
  justify-content: space-around;
  align-items: center;
  border-top: 1px solid rgb(224,224,224);	
}

.list-contents {
  display: flex;
  align-items: center;
  height: 53px;
  border-top: 0.5px solid rgb(224,224,224);	
  border-bottom: 0.5px solid rgb(224,224,224);
}

.list-contents:hover {
  background-color: rgb(245,245,245);
  cursor: pointer;
}

.list-content-name {
  width: 16%;
  text-align: left;
  padding-left: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 10px;
}

.list-content-manager {
  width: 16%;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.list-content-domain {
  width: 16%;
  text-align: center;
}

.list-content-user {
  width: 16%;
  text-align: center;
}

.list-content-license {
  width: 19%;
  text-align: center;
}

.list-content-regdate {
  width: 16%;
  text-align: center;
}

.list-margin-right {
  margin-right: 10px;
}

.list-skeleton {
  display: flex;
  gap: 20px;
}

@media (max-width: 1200px) {
  .list-wrapper {
    width: 1100px;
    margin-top: 40px;
  }
}

@media (max-width: 900px) {
  .list-wrapper {
    width: 830px;
    margin-top: 40px;
  }
}